<template>
  <b-modal
    id="conflicted-guides-modal"
    hide-header
    hide-footer
    centered
    size="lg"
    @show="clearProviderGuideUpdated"
  >
    <div class="modal-header">
      <div>
        <p class="modal-title">Número das guias em conflito</p>
      </div>
      <div>
        <Close class="icon" @click="clearModal" />
      </div>
    </div>
    <div class="around-content">
      <div class="body">
        <div class="body-content">
          <div class="alert-wrapper">
            <!-- <Warning class="warning-svg" /> -->
            <p class="modal-text">
              Atenção! Já existe uma guia com esse Nº, selecione um novo nº de guia para cada guia abaixo. Isso poderá afetar também o Nº
              das guias subsequentes.
            </p>
          </div>
          <br />
          <!-- <b-button
            variant="outline-warning"
            @click="autoGenerate"
          >
            Gerar automaticamente
          </b-button> -->
        </div>
      </div>
      <div class="table-container">

        <b-table-simple class="table" responsive borderless fixed>
          <thead>
            <tr>
                <th><Ellipsis>Paciente</Ellipsis></th>
                <th><Ellipsis>Autorização</Ellipsis></th>
                <th><Ellipsis>Tipo</Ellipsis></th>
                <th><Ellipsis>Nº da guia atual</Ellipsis></th>
                <th><Ellipsis>Novo nº da guia</Ellipsis></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) of conflictedGuides" :key="index">
              <td><Ellipsis>{{ item.patient.name }}</Ellipsis></td>
              <td><Ellipsis>{{ item.authorization_date ? moment(item.authorization_date).format('DD/M/Y') : '-' }}</Ellipsis></td>
              <td><Ellipsis>{{ item.guide_type ? parseGuideType(item.guide_type) : '-' }}</Ellipsis></td>
              <td><Ellipsis>{{ item.provider_guide_prop || item.provider_guide }}</Ellipsis></td>
              <td>
                <b-form-input
                  :value="item.provider_guide_update"
                  @input="$event => updateProviderGuideNumber(index, $event)"
                  :state="isValid()"
                />
                <b-form-invalid-feedback :state="isValid()">
                  <div v-if="conflictedGuides.filter(el => el?.provider_guide_update && el.provider_guide_update === item.provider_guide_update).length > 1">
                    Existem números de guia repetidos
                  </div>
                </b-form-invalid-feedback>
              </td>
            </tr>
          </tbody>
        </b-table-simple>
      </div>
      <div class="wrapper-button">
        <b-button variant="link" @click="clearModal">
          Cancelar e voltar
        </b-button>
        <b-button
          class="ml-auto"
          variant="primary"
          :disabled="disableButton"
          @click="updateProviderGuide"
        >
          Salvar alterações
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'ConflictedGuidesModal',
  props: {
    conflictedGuides: {
      type: Array,
      required: true
    },
    clearProviderGuideUpdated: {
      type: Function,
      required: true
    },
    currentGuideNumber: {
      type: Number
    },
  },
  components: {
    Ellipsis: () => import('@/components/General/Ellipsis'),
    Close: () => import('@/assets/icons/close.svg'),
    // Warning: () => import('@/assets/icons/warning.svg')
  },
  data() {
    return {
      clinic: getCurrentClinic(),

    }
  },
  computed: {
    disableButton() {
      return !this.isValid()
    }
  },
  methods: {
    clearModal() {
      this.$bvModal.hide('conflicted-guides-modal')
    },
    updateProviderGuideNumber(index, $event) {
      const conflictedGuides = [...this.conflictedGuides]
      conflictedGuides[index].provider_guide_update = $event
      this.$emit('update-conflicted-guides', conflictedGuides)
    },
    autoGenerate() {
      const conflictedGuides = [...this.conflictedGuides]
      conflictedGuides.forEach((guide, index) => {
        guide.provider_guide_update = this.currentGuideNumber + index
      })
      this.$emit('update-conflicted-guides', conflictedGuides)
    },
    async updateProviderGuide() {
      const isLoading = this.$loading.show()
      try {
        this.$emit('update-provider-guide', this.conflictedGuides[0].provider_guide_update)
        if (!this.conflictedGuides[0].id) {
          this.conflictedGuides.shift()
        }
        await this.api.updateManyTissProviderGuide({
          guides: this.conflictedGuides.map(guide => ({
            id: guide.id,
            provider_guide: guide.provider_guide_update,
            clinic_id: this.clinic.id
          }))
        })
        this.$toast.success('As alterações foram salvas com sucesso!')
        this.$emit('update-invalid-guides', false)
        this.clearModal()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    isValid() {
      return this.conflictedGuides.every(guide => guide.provider_guide_update) && 
        !this.hasDuplicated()
    },
    hasDuplicated() {
      const guideNumbers = []
      return this.conflictedGuides.some(guide => {
        if (guideNumbers.includes(guide.provider_guide_update)) {
          return true
        }
        guideNumbers.push(guide.provider_guide_update)
        return false
      })
    },
    parseGuideType(guideType) {
      switch(guideType) {
        case 'App\\Models\\TissGuide\\TissGuideConsultation':
          return 'Guia de consulta'
        case 'App\\Models\\TissGuide\\TissGuideSpSadt':
          return 'Guia SP/SADT'
        case 'App\\Models\\TissGuide\\TissGuideHospitalizationRequest':
          return 'Guia de solicitação de internação'
        case 'App\\Models\\TissGuide\\TissGuideHospitalizationSummary':
          return 'Guia de resumo de internação'
        case 'App\\Models\\TissGuide\\TissGuideOtherExpense':
          return 'Guia de outras despesas'
        case 'App\\Models\\TissGuide\\TissGuideFee':
          return 'Guia de honorários'
        default:
          return guideType
      }
    },
  }
}
</script>
<style lang="scss" scoped>
 @import '@/assets/scss/table.scss';
 .alert-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 20px;
 }
 .action-button {
    color: var(--primary-500);
    font-weight: 700;
    cursor: pointer;
 }
</style>
<style lang="scss">
#conflicted-guides-modal {
  .modal-content {
    border-radius: 8px;

    .modal-body {
      padding: 0 !important;
      margin: 0 !important;

      .modal-header {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px !important;

        .modal-title {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: var(--type-active);
        }

        .icon {
          width: 25px;
          fill: var(--neutral-500);
        }
      }

      .around-content {
        padding: 1rem;
        .body {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .body-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 20px 0;
            font-size: 16px;

            .text-content {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 70%;

              .modal-text {
                font-weight: 600;
                font-size: 16px;
              }
            }

            .warning-svg {
              height: 32px;
              width: 32px;
            }
          }
        }
        .table-container {
          // max-height: 500px !important;
          // overflow-y: scroll;
          #conflicted-guides-table {
            margin: 0 auto;
            thead {
              position: sticky;
              top: 0;
              z-index: 100;
              background-color: var(--neutral-000);

              th {
                box-shadow: inset 0 -2px 0 var(--neutral-300);
              }
            }
          }
        }
      }

      .wrapper-button {
        width: 100%;
        display: flex;
        margin: 20px 0 !important;
        justify-content: flex-start;

        button {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--neutral-300);
      border-radius: 100px;
    }
  }
}
</style>
